import Component from '../lib/Component'
import DropDown from './DropDown'
import gsap from 'gsap'

export default class DropDownButton extends Component {
    control: NodeListOf<Element>
    elementID:string | null
    direction: string | undefined
    elements: NodeListOf<HTMLElement>
    animationFrom: {}
    animationTo: {}
    gsap:GSAPTween
    parentComponent: DropDown
    isOpen:boolean = false

    mount() {
        const _control = this.getControl()
        if(!_control) return
        this.control = _control

    }

    onChange(isOpen?:boolean){
        if(isOpen == this.isOpen) return
        this.isOpen = (isOpen)? isOpen : !this.isOpen;
        this.setState()
        if(this.isOpen){
            this.slideDown()
            this.parentComponent.store.controlName = this.elementID
        }else{
            this.SlideUp()
        }
    }

    slideDown(){
        this.setAnimation()
        this.gsap = this.gsap.duration(0.2).play()
    }

    SlideUp(){
        this.setAnimation()
        this.gsap = this.gsap.duration(0.2).reverse(1);
    }

    getControl(){
        this.elementID = this.element.getAttribute("id")
        return this.elementID? document.querySelectorAll('[aria-labelledby='+this.elementID+']') : null
    }

    setState(){
        this.element.setAttribute('aria-expanded',String(this.isOpen))
    }

    setParent(component:DropDown){
        this.parentComponent = component
    }

    onEvent(){
        Array.from(this.control).forEach((_el) => {
            const el = <HTMLElement>_el
            el.style.overflow = 'hidden'
            el.style.height = '0'
            el.style.visibility = 'visible'
        });
        this.element.setAttribute('aria-expanded',"false")
        this.on(this.parentComponent.event,(event)=>{this.action(event)})
    }

    offEvent(){
        Array.from(this.control).forEach((_el) => {
            const el = <HTMLElement>_el
            el.removeAttribute('style');
        });
        this.off(this.parentComponent.event,(event)=>{this.action(event)})
    }

    action(event){
        event.preventDefault()
        this.onChange()
    }

    setAnimation(){
        this.animationFrom = {height:0}
        this.animationTo = {height:"auto"}
        this.gsap = gsap.fromTo('[aria-labelledby='+this.elementID+']', this.animationFrom,this.animationTo).pause()
    }

}

