export type ConfigType = {[key: string]: any}

const config:ConfigType = {
  MEDIA_QUERY: {
    phone: 'screen and (max-width: 899px)',
    desktop: 'screen and (min-width: 900px)',
  },
  SPLASH_SCREEN_DURATION: 3500,
  SPLASH_SCREEN_SELECTOR: '.js-splashscreen',
  time: 0,
}

declare global {
  interface Window {
    config: ConfigType,
  }
}

window.config = config;

const configure = () => {
  window.config = config;
}

export {
  configure,
}

