const domLoaded = () => new Promise(resolve => {
  if (document.readyState !== "loading") {
    /*Logger.log('document.readyState: ' + document.readyState)*/
    console.log('document.readyState: ' + document.readyState);
    setImmediate(() => resolve())
    return
  }
  window.addEventListener('load', function () {
    /*Logger.log('window.load')*/
    console.log('load');
    resolve()
  })
  document.addEventListener('DOMContentLoaded', function () {
    /*Logger.log('DOMContentLoaded')*/
    console.log('DOMContentLoaded');
    resolve()
  })
})

export {
domLoaded
}
