import EventEmitter from "events"
import Time from '../lib/Time'
import imagesLoaded from 'imagesloaded';

export default class Loading extends EventEmitter{
  static COMPLETE = 'complete'
  static PROGRESS = 'progress'
  static FAIL = 'fail'

  loader: any
  target: ImagesLoaded.ElementSelector = 'body'
  imageTotalCount:number
  _loaded:boolean = false

  constructor(selector?:Element){
    super();
    if(selector){
      this.target = selector;
    }
    this.loader = imagesLoaded(this.target,() => {
      this._loaded = true
      this._complete()
    })
    this.loader.on('progress',this.emit(Loading.PROGRESS))
    this.loader.on('fail',this.emit(Loading.FAIL))
    this.imageTotalCount = this.loader.images.length;
  }

  _complete() {
    const delay = Math.max(window.config.SPLASH_SCREEN_DURATION - Time.create().end('init'), 0)
    setTimeout(() => {
      this.emit(Loading.COMPLETE)
    }, delay)
  }

  get loaded(): boolean {
    return this._loaded
  }

  observe() {
    /*if (this.loader.getLoaded(this.getPreloadImages())) {
      setImmediate(this._complete.bind(this))
    } else {
      this.loader.on(Loader.FILELOAD, this.onFileLoad)
    }*/
  }
}

