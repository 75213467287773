import Component from '../lib/Component'
import Swiper from 'swiper'

export default class TopStudiosSlider extends Component {
    view() {
        new Swiper(this.element, {
            effect: 'fade',
            speed: 1200,
            loop: true,
            autoplay: {
                delay: 3000,
            },
        });
    }
}


