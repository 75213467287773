import Component from '../lib/Component'
import Swiper from 'swiper'

export default class SplashScreen extends Component {
    mount() {
        this.element.style.display = 'none'
        if(window.location.pathname == '/'){
            window.onbeforeunload = function () {
                window.scrollTo(0,0);
            };
        }

        document.body.classList.add('is-loading')
    }

    view() {
        //this.element.classList.add('hide')
        console.log("view")

        document.body.classList.remove('is-loading')
        document.body.classList.add('is-loaded')
    }
}


