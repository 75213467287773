import './polyfill'
import SplashScreen from "./components/SplashScreen"
import Body from './components/Body'
import PageScroll from "./components/PageScroll"
import MenuButton from "./components/MenuButton"
import HeroSlider from "./components/HeroSlider"
import TopNewsSlider from "./components/TopNewsSlider"
import TopStudiosSlider from "./components/TopStudiosSlider"
import RecruitSlider from "./components/RecruitSlider"
import DropDown from "./components/DropDown"

import {configure} from './config'
import Time from './lib/Time'
import ComponentManager from './lib/ComponentManager'
import {domLoaded} from './lib/promisfy'



const applicationSequence = async () => {
    configure()
    Time.create().start('init')
    await domLoaded()

    const _ComponentManager = new ComponentManager({
        [window.config.SPLASH_SCREEN_SELECTOR]: SplashScreen,
        'body': Body,
        "[href^='#']": PageScroll,
        '.js-hero-slider': HeroSlider,
        '.js-menu-button': MenuButton,
        '.js-top-news-slider': TopNewsSlider,
        '.js-top-studios-slider': TopStudiosSlider,
        '.js-recruit-slider': RecruitSlider,
        '.js-dropdown': DropDown,
    })
    _ComponentManager.start()

   /* console.log(new Body('.js-header'));*/
}


applicationSequence()