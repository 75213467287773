import Component from '../lib/Component'
import Swiper from 'swiper'

export default class TopNewsSlider extends Component {
    mount() {
        new Swiper(this.element, {
            slidesPerView: 'auto',
            spaceBetween: 20,
            watchOverflow:true,
            navigation: {
                nextEl: '.swiper-button-next',
            },
            breakpoints: {
                900: {
                    spaceBetween: 60,
                }
            }
        });
    }

}

