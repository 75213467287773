import Component from '../lib/Component'
import Swiper from 'swiper'

export default class TopCampaginSlider extends Component {
    swiper: any

    mount() {
        this.changeSlider()
    }

    changeMediaquery() {
        super.changeMediaquery()
        this.changeSlider()
    }

    changeSlider(){
        if(this.mediaQuery.screenMode == 'phone'){
            this.swiper = new Swiper(this.element, {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
            });
        }
        if(this.mediaQuery.screenMode == 'desktop'){
            if(this.swiper){
                this.swiper.destroy( true, true );
            }
        }

    }

}

