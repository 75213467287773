import Component from '../lib/Component'
import ComponentArray from '../lib/ComponentArray'
import DropDownStore from '../store/DropDown'
import DropDownButton from './DropDownButton'
import {autorun} from 'mobx'

export type ComponentList = () => Component[]

export default class DropDown extends Component {
    store = new DropDownStore()
    buttons:ComponentArray
    device:string | null
    event:string
    mount() {
        this.device = this.element.getAttribute('data-device')
        this.event = this.element.getAttribute('data-hover')? 'mouseover' : 'click'
        if(this.mediaQuery.isDesktop() && this.event == 'mouseover'){
            this.on('mouseleave', () => {
                this.store.controlName = null;
            });
            autorun(() => {
                this.buttons.map((_c) => {
                    const c = <DropDownButton>_c
                    if (this.store.controlName != c.elementID) {
                        c.onChange(false)
                    }
                })
            });
        }
        this.resize()
        /*this.buttons.filter((value,index) => index == 0).map(c => {
            c.emit('click');
        });*/
    }

    relationButton(){
        this.buttons = new ComponentArray(DropDownButton, () => this.element.querySelectorAll('.js-dropdown-button'))
        this.buttons.map((_c) => {
            const c = <DropDownButton>_c
            c.setParent(this)
            if(this.device && (this.mediaQuery.screenMode != this.device)) {
                c.unmount();
                c.offEvent();
            }else{
                c.onEvent();
            }
        });
    }

    resize(){
        if(this.mediaQuery.isPhone() && this.event == 'mouseover'){
            this.event = 'click';
        }
        this.relationButton();
        this.store.controlName = null;
    }

    changeMediaquery() {
        this.relationButton();
        this.resize();
    }
}

